<template>
  <v-sheet ref="myhtml" :class="{ 'overflow-x-auto': overflow }">
    <v-card
      min-height="100vh"
      flat
      max-width="842"
      :min-width="overflow ? 842 : null"
      id="html"
      class="
        invoice-template-default-4
        fill-height
        overflow-hidden
        border-color--grey
      "
      outlined
      :color="colors.body_bg.color"
    >
      <v-row
        dense
        class="border-bottom-10 rounded-bl-0 rounded-br-0"
        no-gutters
        align="center"
        justify="start"
        :style="`background-color:${colors.header_bg.color};border-color:${colors.border_shade.color};height:100px;`"
      >
        <v-avatar tile class="ml-10" height="50" max-width="120" width="100%">
          <v-img
            :lazy-src="settings.loader"
            contain
            :src="invoice_logo"
          ></v-img>
        </v-avatar>
      </v-row>
      <v-row align="start" dense justify="center">
        <v-col cols="10" class="d-flex align-start justify-center flex-column">
          <div class="py-13">
            <h1
              :style="`color:${colors.body_text.color};`"
              class="fw-900 fs-55 ls-10"
            >
              INVOICE
            </h1>
          </div>
          <v-row
            align="stretch"
            dense
            :style="`color:${colors.body_text.color};`"
            justify="center"
            class="border-top-2 fullwidth border-color--grey"
          >
            <v-col
              cols="4"
              class="d-flex pa-3 align-start justify-start flex-column"
            >
              <strong class="fw-400 fs-13">Invoice From</strong>
              <span class="fw-700 fs-15">
                {{ billable_from ? billable_from.fullname : '-' | ucwords }}
              </span>
              <strong class="fw-400 fs-13 mt-2">Email</strong>
              <span class="fw-700 fs-15">
                {{
                  billable_from ? billable_from.email : '-' | trunc_mid(10, 10)
                }}
              </span>
            </v-col>
            <v-col
              cols="4"
              class="
                border-left-2
                d-flex
                pa-3
                align-start
                justify-start
                flex-column
                border-right-2
                border-color--grey
              "
            >
              <strong class="fw-400 fs-13">Invoice To</strong>
              <span class="fw-700 fs-15">
                {{ billable_to ? billable_to.fullname : '-' | ucwords }}
              </span>
              <strong class="fw-400 fs-13 mt-2">Email</strong>
              <span class="fw-700 fs-15">
                {{ billable_to ? billable_to.email : '-' | trunc_mid(10, 10) }}
              </span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex pa-3 align-start justify-start flex-column"
            >
              <strong class="fw-400 fs-13 mb-3">Invoice Details</strong>
              <div>
                <span class="fw-700">Invoice Date: </span>
                <span v-if="invoice && invoice.type === 'one-time'">
                  {{ invoice.billing_date | format('ll') }}
                </span>
                <span v-else>TBA</span>
              </div>
              <div>
                <span class="fw-700">Issue Date:</span>
                <span v-if="invoice && invoice.type === 'one-time'">
                  {{ invoice.billing_date | format('ll') }}
                </span>
                <span v-else>TBA</span>
              </div>
              <div>
                <span class="fw-700">Invoice No.:</span>
                <span>
                  {{ invoice && invoice.id ? `INV-${invoice.id}` : 'INV-TBA' }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-sheet
            min-height="100"
            class="
              item-body
              fullwidth
              border-bottom-1
              border-color--grey
              rounded-1
              mt-5
              mb-5
            "
            :color="colors.body_bg.color"
          >
            <table
              class="item-table fullwidth border-bottom-2 border-color--grey"
            >
              <thead :style="`background-color:${colors.header_bg.color};`">
                <tr>
                  <th
                    :style="`color:${colors.header_text.color};text-align:left;`"
                    class="py-3 pl-5"
                  >
                    Item Description
                  </th>
                  <th
                    :style="`color:${colors.header_text.color};`"
                    class="text-center py-3"
                    :width="100"
                  >
                    Unit Price
                  </th>
                  <th
                    :style="`color:${colors.header_text.color};`"
                    class="text-center py-3"
                    :width="100"
                  >
                    Quantity
                  </th>
                  <th
                    :style="`color:${colors.header_text.color};`"
                    class="text-center py-3"
                    :width="100"
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody v-if="invoice">
                <tr
                  v-for="(item, index) in invoice.items"
                  :key="index"
                  class="fw-500"
                  :style="`color:${colors.body_text.color};`"
                >
                  <td class="py-3 px-1">
                    {{ item.description || 'No description' | ucwords }}
                  </td>
                  <td class="text-center">{{ item.price_per_unit | money }}</td>
                  <td class="text-center">{{ item.quantity }}</td>
                  <td class="text-center">{{ item.total | money }}</td>
                </tr>
              </tbody>
            </table>
          </v-sheet>
          <v-row
            dense
            class="fullwidth"
            align="stretch"
            justify="start"
            :style="`color:${colors.body_text.color};`"
          >
            <v-col
              cols="6"
              :style="`color:${colors.body_text.color};`"
              class="d-flex align-start justify-start pa-3 flex-column"
            >
              <strong class="fw-700 fs-20 mb-4">Contact Us</strong>
              <template v-if="invoice_company">
                <span class="fw-900 ls-2 fs-18">
                  {{ invoice_company.name }}
                </span>
                <span class="fw-400 fs-13"> {{ invoice_company.email }} </span>
                <span class="fw-400 fs-13" v-if="invoice_company.contact">
                  {{ invoice_company.contact.e164 }}
                </span>
                <span class="fw-400 fs-13" v-if="invoice_company.domain">
                  {{ invoice_company.domain }}
                </span>
              </template>
            </v-col>
            <v-col
              cols="6"
              class="d-flex align-stretch justify-center pa-3 flex-column"
            >
              <v-row dense align="center" justify="space-between">
                <v-col
                  :style="`color:${colors.body_text.color};`"
                  cols="6"
                  class="text-right fw-500"
                >
                  Subtotal:
                </v-col>
                <v-col cols="6" class="text-right fw-600">
                  {{ sub_total | money }}
                </v-col>
              </v-row>
              <v-row dense align="center" justify="space-between">
                <v-col
                  :style="`color:${colors.body_text.color};`"
                  cols="6"
                  class="text-right fw-500"
                >
                  Discount:
                </v-col>
                <v-col cols="6" class="text-right fw-600">
                  {{ discount_amount | money }}
                </v-col>
              </v-row>
              <v-row dense align="center" justify="space-between">
                <v-col
                  :style="`color:${colors.body_text.color};`"
                  cols="6"
                  class="text-right fw-500"
                >
                  Shipping:
                </v-col>
                <v-col cols="6" class="text-right fw-600">
                  {{ shipping_amount | money }}
                </v-col>
              </v-row>
              <v-row dense align="center" justify="space-between">
                <v-col
                  :style="`color:${colors.body_text.color};`"
                  cols="6"
                  class="text-right fw-500"
                >
                  Tax:
                </v-col>
                <v-col cols="6" class="text-right fw-600">
                  {{ tax_amount | money }}
                </v-col>
              </v-row>
              <v-divider class="my-2" style="background: #e0e0e0" />
              <v-row dense align="center" justify="space-between">
                <v-col cols="6" class="text-right fw-700 fs-18">
                  Grand Total:
                </v-col>
                <v-col cols="6" class="text-right fw-700 fs-18">
                  {{ total_after_addons | money }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        dense
        class="border-top-5 mt-5 px-10 py-2 rounded-bl-0 rounded-br-0"
        no-gutters
        align="stretch"
        justify="start"
        :style="`color:${colors.footer_text.color};background-color:${colors.footer_bg.color};border-color:${colors.border_shade.color};`"
      >
        <v-col
          cols="6"
          class="d-flex align-start flex-column px-2 justify-start"
        >
          <strong>Notes</strong>
          <p class="caption">
            {{ invoice && invoice.notes ? invoice.notes : '' | truncate(400) }}
          </p>
        </v-col>
        <v-col
          cols="6"
          class="d-flex align-start flex-column px-2 justify-start"
        >
          <strong>Terms & Conditions</strong>
          <p class="caption">
            {{ invoice && invoice.terms ? invoice.terms : '' | truncate(400) }}
          </p>
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: 'InvoiceDefault4',
  props: {
    value: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    createMode: { type: Boolean, default: true },
    overflow: { type: Boolean, default: false }
  },
  data: () => ({
    colors: {
      body_bg: {
        color: '#ffffff',
        name: 'Body Background Color',
        show: false
      },
      body_text: {
        color: '#383737',
        name: 'Body Text Color',
        show: false
      },
      header_bg: {
        color: '#3c599e',
        name: 'Header Background Color',
        show: false
      },
      header_text: {
        color: '#FFFFFFFF',
        name: 'Header Text Color',
        show: false
      },
      border_shade: {
        color: 'blue',
        name: 'Border Shade Background Color',
        show: false
      },
      footer_bg: {
        color: '#D6D3CFFF',
        name: 'Footer Background Color',
        show: false
      },
      footer_text: {
        color: '#2E2B2BFF',
        name: 'Footer Text Color',
        show: false
      }
    },
    html: ''
  }),
  computed: {
    ...mapGetters('invoice', [
      'invoice',
      'invoice_company',
      'invoice_logo',
      'billable_from',
      'billable_to',
      'sub_total',
      'tax_amount',
      'shipping_amount',
      'discount_amount',
      'total_after_addons'
    ]),
    today() {
      return moment().format('ll')
    }
  },
  created() {
    this.$emit('input', this.colors)
  },
  methods: {
    test() {
      //   this.$emit("html", this.$refs.myhtml);
      console.log(this.$refs.myhtml.$el.innerHTML)
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) this.colors = val
      },
      immediate: true,
      deep: true
    },
    colors: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-template-default-4 {
  .item-table {
    border-collapse: collapse;
    tr {
      border: none;
    }
    td {
      border-bottom: 2px solid #e0e0e0;
      padding: 20px auto;
    }
  }
}
</style>
