<template>
  <v-sheet ref="myhtml" :class="{ 'overflow-x-auto': overflow }">
    <v-card
      flat
      max-width="842"
      :min-width="overflow ? 842 : null"
      id="html"
      class="
        invoice-template-default-2
        fill-height
        overflow-hidden
        border-color--grey
      "
      outlined
      color="white"
    >
      <v-row
        dense
        no-gutters
        class="fill-height"
        align="stretch"
        justify="start"
      >
        <v-col
          cols="4"
          :style="`background-color:${colors.left_bg.color};`"
          class="d-flex align-start flex-column justify-start"
        >
          <v-sheet
            :style="`color:${colors.left_text.color};`"
            color="transparent"
            class="py-10 fullwidth mt-5 px-10 d-flex flex-column"
          >
            <strong class="fs-15 fw-700 mb-1">
              Invoice No.
              {{ invoice && invoice.id ? `INV-${invoice.id}` : 'INV-TBA' }}
            </strong>
            <span
              class="fs-12 fw-500 mb-1"
              v-if="invoice && invoice.type === 'one-time'"
            >
              Issued:
              {{ invoice.billing_date | format('LL') }}
            </span>
            <span v-else>Issued: TBA</span>
            <span
              class="fs-12 fw-500"
              v-if="invoice && invoice.type === 'one-time'"
            >
              Due:
              {{ invoice.due_date | format('LL') }}
            </span>
            <span v-else>Payment Due: TBA</span>
          </v-sheet>
          <v-sheet
            color="transparent"
            :style="`color:${colors.left_text.color};`"
            class="py-10 mt-5 px-10 d-flex flex-column"
          >
            <strong class="fs-19 fw-700 ls-unset mb-3">INVOICE FROM:</strong>
            <strong class="fs-17 fw-700 mb-1">
              {{ billable_from ? billable_from.fullname : 'Unknown' }}
            </strong>
            <span class="fs-12 fw-500">
              {{ billable_from ? billable_from.email : '-' }}
            </span>
            <span class="fs-12 fw-500">
              {{
                billable_from && billable_from.contact_number
                  ? billable_from.contact_number
                  : '-'
              }}
            </span>
          </v-sheet>
          <v-sheet
            height="180"
            :color="colors.logo_bg.color"
            class="rounded-0 fullwidth my-5 d-flex align-center justify-center"
          >
            <v-avatar tile height="100" max-width="200" width="100%">
              <v-img
                :lazy-src="settings.loader"
                contain
                :src="invoice_logo"
              ></v-img>
            </v-avatar>
          </v-sheet>
          <v-sheet
            color="transparent"
            :style="`color:${colors.left_text.color};`"
            class="py-10 mt-5 px-10 d-flex flex-column"
          >
            <strong class="fs-19 fw-700 ls-unset mb-3">CONTACT US:</strong>
            <strong class="fs-17 fw-700 mb-1">Phone</strong>
            <span class="mb-3">
              {{
                invoice_company && invoice_company.contact
                  ? invoice_company.contact.e164
                  : '-'
              }}
            </span>
            <strong class="fs-17 fw-700 mb-1">Email</strong>
            <span class="mb-3">
              {{
                invoice_company && invoice_company.email
                  ? invoice_company.email
                  : '-'
              }}
            </span>
            <strong class="fs-17 fw-700 mb-1">Address</strong>
            <span class="mb-3">
              {{
                invoice_company && invoice_company.address
                  ? invoice_company.address
                  : '-'
              }}
            </span>
          </v-sheet>
          <v-sheet
            color="transparent"
            :style="`color:${colors.left_text.color};`"
            class="py-10 mt-5 px-10 d-flex flex-column"
          >
            <strong class="fs-19 fw-700 ls-unset mb-3">Term & Condition</strong>
            <p class="caption mb-1">
              {{
                invoice && invoice.terms ? invoice.terms : '-' | truncate(400)
              }}
            </p>
          </v-sheet>
        </v-col>
        <v-col
          cols="8"
          :style="`background-color:${colors.right_bg.color};`"
          class="d-flex pa-7 flex-column align-stretch justify-start"
        >
          <v-sheet
            color="transparent"
            :style="`color:${colors.right_text.color};`"
            class="d-flex mb-5 mt-10 fullwidth align-center justify-end"
          >
            <h1 class="fw-900 fs-50">INVOICE</h1>
          </v-sheet>
          <v-sheet
            color="transparent"
            :style="`color:${colors.right_text.color};`"
            class="
              d-flex
              flex-column
              mb-5
              mt-10
              fullwidth
              align-end
              justify-end
            "
          >
            <strong class="mb-3 fs-19 fw-700">INVOICE TO:</strong>
            <strong class="fs-17 fw-700">
              {{ billable_to ? billable_to.fullname : '-' | ucwords }}
            </strong>
            <span class="fs-12 fw-500">
              {{ billable_to ? billable_to.email : '-' }}
            </span>
            <span class="fs-12 fw-500">
              {{
                billable_to && billable_to.contact_number
                  ? billable_to.contact_number
                  : '-'
              }}
            </span>
          </v-sheet>
          <v-sheet min-height="100" class="item-body rounded-2 mt-5 mb-5">
            <table class="item-table fullwidth">
              <thead :style="`background-color:${colors.left_bg.color};`">
                <tr>
                  <th :style="`color:${colors.left_text.color};`" class="py-3">
                    Items
                  </th>
                  <th
                    :style="`color:${colors.left_text.color};`"
                    class="text-center py-3"
                    :width="100"
                  >
                    Unit Price
                  </th>
                  <th
                    :style="`color:${colors.left_text.color};`"
                    class="text-center py-3"
                    :width="100"
                  >
                    Quantity
                  </th>
                  <th
                    :style="`color:${colors.left_text.color};`"
                    class="text-center py-3"
                    :width="100"
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody v-if="invoice">
                <tr
                  v-for="(item, index) in invoice.items"
                  :key="index"
                  :style="`color:${colors.right_text.color};border-color:${colors.right_text.color} !important;`"
                  class="fw-500"
                >
                  <td
                    :style="`border-color:${colors.right_text.color};`"
                    class="py-3 px-1"
                  >
                    {{ item.description || 'No description' | ucwords }}
                  </td>
                  <td
                    :style="`border-color:${colors.right_text.color};`"
                    class="text-center"
                  >
                    {{ item.price_per_unit | money }}
                  </td>
                  <td
                    :style="`border-color:${colors.right_text.color};`"
                    class="text-center"
                  >
                    {{ item.quantity }}
                  </td>
                  <td
                    :style="`border-color:${colors.right_text.color};`"
                    class="text-center"
                  >
                    {{ item.total | money }}
                  </td>
                </tr>
              </tbody>
            </table>
            <v-row dense class="my-5" align="start" justify="space-between">
              <v-col :cols="6" class="d-flex flex-column">
                <strong
                  class="fw-500 fs-20"
                  :style="`color:${colors.right_text.color};`"
                >
                  Notes
                </strong>
                <div class="d-flex align-center justify-start mt-3">
                  <p
                    class="pr-4 caption"
                    :style="`color:${colors.right_text.color};`"
                  >
                    {{ invoice ? invoice.notes : '-' | truncate(400) }}
                  </p>
                </div>
              </v-col>
              <v-col :cols="6" class="d-flex flex-column">
                <v-row
                  :style="`color:${colors.right_text.color};border-color:${colors.right_text.color};`"
                  dense
                  class="border-1 py-3"
                  align="center"
                  no-gutters
                  justify="space-between"
                >
                  <v-col
                    cols="5"
                    class="fw-600 d-flex align-center justify-end"
                  >
                    Subtotal:
                  </v-col>
                  <v-col cols="7" class="d-flex align-center justify-center">
                    {{ sub_total | money }}
                  </v-col>
                </v-row>
                <v-row
                  class="border-1 py-3"
                  dense
                  no-gutters
                  :style="`color:${colors.right_text.color};border-color:${colors.right_text.color};`"
                  align="center"
                  justify="space-between"
                >
                  <v-col
                    cols="5"
                    class="fw-600 d-flex align-center justify-end"
                  >
                    Discount:
                  </v-col>
                  <v-col cols="7" class="d-flex align-center justify-center">
                    {{ discount_amount | money }}
                  </v-col>
                </v-row>
                <v-row
                  class="border-1 py-3"
                  dense
                  no-gutters
                  :style="`color:${colors.right_text.color};border-color:${colors.right_text.color};`"
                  align="center"
                  justify="space-between"
                >
                  <v-col
                    cols="5"
                    class="fw-600 d-flex align-center justify-end"
                  >
                    Shipping:
                  </v-col>
                  <v-col cols="7" class="d-flex align-center justify-center">
                    {{ shipping_amount | money }}
                  </v-col>
                </v-row>
                <v-row
                  class="border-1 py-3"
                  dense
                  no-gutters
                  :style="`color:${colors.right_text.color};border-color:${colors.right_text.color};`"
                  align="center"
                  justify="space-between"
                >
                  <v-col
                    cols="5"
                    class="fw-600 d-flex align-center justify-end"
                  >
                    Tax:
                  </v-col>
                  <v-col cols="7" class="d-flex align-center justify-center">
                    {{ tax_amount | money }}
                  </v-col>
                </v-row>
                <v-row
                  class="border-1 py-3"
                  dense
                  no-gutters
                  :style="`background-color:${colors.left_bg.color};color:${colors.left_text.color};border-color:${colors.right_text.color};`"
                  align="center"
                  justify="space-between"
                >
                  <v-col
                    cols="5"
                    class="fw-700 fs-20 d-flex align-center justify-end"
                  >
                    Total:
                  </v-col>
                  <v-col
                    cols="7"
                    class="d-flex fw-700 fs-20 align-center justify-center"
                  >
                    {{ total_after_addons | money }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet
            height="180"
            color="transparent"
            class="rounded-0 fullwidth my-1 d-flex align-center justify-end"
          >
            <v-avatar tile height="100" max-width="200" width="100%">
              <v-img
                :lazy-src="settings.loader"
                contain
                :src="invoice_logo"
              ></v-img>
            </v-avatar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: 'InvoiceDefault2',
  props: {
    value: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    createMode: { type: Boolean, default: true },
    overflow: { type: Boolean, default: false }
  },
  data: () => ({
    colors: {
      left_bg: {
        color: '#1ba6df',
        name: 'Left Side Background Color',
        show: false
      },
      left_text: {
        color: '#FFFFFFFF',
        name: 'Left Side Text Color',
        show: false
      },
      right_bg: {
        color: '#3c599e',
        name: 'Right Side Background Color',
        show: false
      },
      right_text: {
        color: '#FFFFFFFF',
        name: 'Right Side Text Color',
        show: false
      },
      logo_bg: {
        color: '#FFFFFFFF',
        name: 'Logo Wrapper Background Color',
        show: false
      }
    },
    html: ''
  }),
  computed: {
    ...mapGetters('invoice', [
      'invoice',
      'invoice_company',
      'invoice_logo',
      'billable_from',
      'billable_to',
      'sub_total',
      'tax_amount',
      'shipping_amount',
      'discount_amount',
      'total_after_addons'
    ]),
    today() {
      return moment().format('ll')
    }
  },
  created() {
    this.$emit('input', this.colors)
  },
  methods: {
    test() {
      //   this.$emit("html", this.$refs.myhtml);
      console.log(this.$refs.myhtml.$el.innerHTML)
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) this.colors = val
      },
      immediate: true,
      deep: true
    },
    colors: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-template-default-2 {
  .item-body {
    background: transparent;
    .item-table {
      border-collapse: collapse;
      tr {
        border: 1px solid #e0e0e0;
      }
      td {
        border: 1px solid #e0e0e0;
      }
    }
  }
}
</style>
