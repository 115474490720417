<template>
  <v-sheet ref="myhtml" :class="{ 'overflow-x-auto': overflow }">
    <v-card
      min-height="100vh"
      flat
      max-width="842"
      :min-width="overflow ? 842 : null"
      id="html"
      class="invoice-template-default-5 fill-height overflow-hidden border-color--grey"
      outlined
      :color="colors.body_bg.color"
    >
      <v-row dense no-gutters align="stretch" justify="center">
        <v-col cols="6" class="d-flex py-10 flex-column">
          <h1 class="fw-900 mb-2 ls-4 fs-50 pl-10">INVOICE</h1>
          <span
            class="pl-10 caption my-1"
            :style="`color:${colors.body_text.color};`"
          >
            No. {{ invoice && invoice.id ? `INV-${invoice.id}` : 'INV-TBA' }}
          </span>
          <div
            :style="`background-color:${colors.header_bg.color};color:${colors.header_text.color};`"
            class="my-3 pl-10 d-flex flex-column py-4"
            style="max-width: 75%"
          >
            <span v-if="invoice && invoice.type === 'one-time'">
              Issued: {{ invoice.billing_date | format('LL') }}
            </span>
            <span v-else> Issued: TBA </span>
            <span v-if="invoice && invoice.type === 'one-time'">
              Due: {{ invoice.due_date | format('LL') }}
            </span>
            <span v-else> Due: TBA </span>
          </div>
          <span
            class="pl-10 fw-500 mb-2"
            :style="`color:${colors.body_text.color};`"
          >
            TOTAL DUE
          </span>
          <strong
            class="pl-10 fw-900 fs-25"
            :style="`color:${colors.rightdiv_bg.color};`"
          >
            $100.00
          </strong>
        </v-col>
        <v-col
          cols="6"
          :style="`background-color:${colors.rightdiv_bg.color};color:${colors.rightdiv_text.color};`"
          class="d-flex align-start justify-start flex-column"
        >
          <v-avatar class="ml-10 mt-3 mb-1" tile width="200" height="100">
            <v-img
              :lazy-src="settings.loader"
              contain
              :src="invoice_logo"
            ></v-img>
          </v-avatar>
          <div style="height: 2px; width: 90%" class="d-block my-1 white"></div>
          <div class="d-flex pl-10 align-start my-3 justify-start flex-column">
            <span class="mb-1">INVOICE FROM</span>
            <strong class="fs-18 fw-700">
              {{ billable_from ? billable_from.fullname : '-' | ucwords }}
            </strong>
            <span class="fs-13">
              {{ billable_from ? billable_from.email : '-' }}
            </span>
          </div>
          <div class="d-flex pl-10 align-start mb-3 justify-start flex-column">
            <span class="mb-1">INVOICE TO</span>
            <strong class="fs-18 fw-700">
              {{ billable_to ? billable_to.fullname : '-' | ucwords }}
            </strong>
            <span class="fs-13">
              {{ billable_to ? billable_to.email : '-' }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-sheet
        min-height="100"
        :color="colors.body_bg.color"
        class="item-body fullwidth rounded-1 mb-5"
      >
        <table class="item-table fullwidth">
          <thead :style="`background-color:${colors.header_bg.color};`">
            <tr>
              <th
                :style="`color:${colors.header_text.color};text-align:left;`"
                class="py-3 pl-5"
              >
                Item Description
              </th>
              <th
                :style="`color:${colors.header_text.color};`"
                class="text-center py-3"
                :width="100"
              >
                Unit Price
              </th>
              <th
                :style="`color:${colors.header_text.color};`"
                class="text-center py-3"
                :width="100"
              >
                Quantity
              </th>
              <th
                :style="`color:${colors.header_text.color};`"
                class="text-center py-3"
                :width="100"
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody v-if="invoice">
            <tr
              v-for="(item, index) in invoice.items"
              :key="index"
              class="fw-500"
              :style="`color:${colors.body_text.color};background-color:${colors.body_bg.color};`"
            >
              <td class="py-3 pr-1 pl-2">
                {{ item.description || 'No description' | ucwords }}
              </td>
              <td class="text-center">{{ item.price_per_unit | money }}</td>
              <td class="text-center">{{ item.quantity }}</td>
              <td class="text-center">{{ item.total | money }}</td>
            </tr>
          </tbody>
        </table>
      </v-sheet>
      <v-row
        dense
        no-gutters
        class="fullwidth pl-10"
        align="stretch"
        justify="start"
      >
        <v-col
          cols="5"
          :style="`color:${colors.body_text.color};`"
          class="d-flex align-start justify-start py-3 pr-3 flex-column"
        >
          <template v-if="invoice_company">
            <strong class="fw-700 fs-20 mb-4">Contact Us</strong>
            <span class="fw-900 ls-2 fs-18"> {{ invoice_company.name }} </span>
            <span class="fw-500 fs-13" v-if="invoice_company.address">
              <span class="fw-600 d-inline-block" style="width: 55px">
                Address:
              </span>
              {{ invoice_company.address }}
            </span>
            <span class="fw-500 fs-13" v-if="invoice_company.email">
              <span class="fw-600 d-inline-block" style="width: 55px">
                Email:
              </span>
              {{ invoice_company.email }}
            </span>
            <span class="fw-500 fs-13" v-if="invoice_company.contact">
              <span class="fw-600 d-inline-block" style="width: 55px">
                Contact:
              </span>
              {{ invoice_company.contact.e164 }}
            </span>
          </template>
        </v-col>
        <v-col
          cols="7"
          :style="`background-color:${colors.rightdiv_bg.color}`"
          class="d-flex align-stretch justify-center pa-3 flex-column"
        >
          <v-row
            dense
            align="center"
            justify="space-between"
            :style="`color:${colors.rightdiv_text.color};`"
          >
            <v-col cols="6" class="text-right fw-500"> Subtotal: </v-col>
            <v-col cols="6" class="text-right fw-600">
              {{ sub_total | money }}
            </v-col>
          </v-row>
          <v-row
            dense
            align="center"
            justify="space-between"
            :style="`color:${colors.rightdiv_text.color};`"
          >
            <v-col cols="6" class="text-right fw-500"> Discount: </v-col>
            <v-col cols="6" class="text-right fw-600">
              {{ discount_amount | money }}
            </v-col>
          </v-row>
          <v-row
            dense
            align="center"
            justify="space-between"
            :style="`color:${colors.rightdiv_text.color};`"
          >
            <v-col cols="6" class="text-right fw-500"> Shipping: </v-col>
            <v-col cols="6" class="text-right fw-600">
              {{ shipping_amount | money }}
            </v-col>
          </v-row>
          <v-row
            dense
            align="center"
            justify="space-between"
            :style="`color:${colors.rightdiv_text.color};`"
          >
            <v-col cols="6" class="text-right fw-500"> Tax: </v-col>
            <v-col cols="6" class="text-right fw-600">
              {{ tax_amount | money }}
            </v-col>
          </v-row>
          <v-row
            dense
            align="center"
            justify="space-between"
            :style="`color:${colors.rightdiv_text.color};`"
          >
            <v-col cols="6" class="text-right fw-700 fs-18"> Total Due: </v-col>
            <v-col cols="6" class="text-right fw-700 fs-18">
              {{ total_after_addons | money }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        dense
        class="mt-5 px-10 py-2 rounded-bl-0 rounded-br-0"
        no-gutters
        :style="`color:${colors.body_text.color};`"
        align="stretch"
        justify="start"
      >
        <v-col
          cols="6"
          class="d-flex align-start flex-column pr-2 justify-start"
        >
          <strong>TERMS & CONDITIONS</strong>
          <p class="caption">
            {{ invoice && invoice.terms ? invoice.terms : '' | truncate(400) }}
          </p>
        </v-col>
        <v-col
          cols="6"
          class="d-flex align-start flex-column px-2 justify-start"
        >
          <strong>NOTES</strong>
          <p class="caption">
            {{ invoice && invoice.notes ? invoice.notes : '' | truncate(400) }}
          </p>
        </v-col>
      </v-row>
      <v-row
        dense
        class="py-2"
        no-gutters
        align="center"
        justify="center"
        :style="`background-color:${colors.footer_bg.color};height:50px;`"
      >
        <h3 class="fw-700 ls-1" :style="`color:${colors.footer_text.color};`">
          THANKS FOR YOUR BUSINESS
        </h3>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: 'InvoiceDefault5',
  props: {
    value: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    createMode: { type: Boolean, default: true },
    overflow: { type: Boolean, default: false }
  },
  data: () => ({
    colors: {
      body_bg: {
        color: '#ffffff',
        name: 'Body Background Color',
        show: false
      },
      body_text: {
        color: '#383737',
        name: 'Body Text Color',
        show: false
      },
      header_bg: {
        color: '#3c599e',
        name: 'Header Background Color',
        show: false
      },
      header_text: {
        color: '#FFFFFFFF',
        name: 'Header Text Color',
        show: false
      },
      rightdiv_bg: {
        color: '#1ba6df',
        name: 'Right Side Background Color',
        show: false
      },
      rightdiv_text: {
        color: '#ffffff',
        name: 'Right Side Text Color',
        show: false
      },
      footer_bg: {
        color: '#524D46FF',
        name: 'Footer Background Color',
        show: false
      },
      footer_text: {
        color: '#FFFFFFFF',
        name: 'Footer Text Color',
        show: false
      }
    },
    html: ''
  }),
  computed: {
    ...mapGetters('invoice', [
      'invoice',
      'invoice_company',
      'invoice_logo',
      'billable_from',
      'billable_to',
      'sub_total',
      'tax_amount',
      'shipping_amount',
      'discount_amount',
      'total_after_addons'
    ]),
    today() {
      return moment().format('ll')
    }
  },
  created() {
    this.$emit('input', this.colors)
  },
  methods: {
    test() {
      //   this.$emit("html", this.$refs.myhtml);
      // console.log(this.$refs.myhtml.$el.innerHTML)
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) this.colors = val
      },
      immediate: true,
      deep: true
    },
    colors: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-template-default-5 {
  .item-table {
    border-collapse: collapse;
    tr {
      border: none;
    }
    td {
      border-bottom: 2px dashed #e0e0e0;
      padding: 20px auto;
    }
  }
}
</style>
