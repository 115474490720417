<template>
  <v-sheet ref="myhtml" :class="{ 'overflow-x-auto': overflow }">
    <v-card
      min-height="500"
      flat
      max-width="842"
      :min-width="overflow ? 842 : null"
      id="html"
      class="invoice-template-default-1 border-color--grey"
      outlined
      :color="colors.body_bg.color"
    >
      <v-sheet
        :color="colors.header_bg.color"
        class="rounded-0 py-10 mb-5 px-10 d-flex flex-column"
        min-height="400"
      >
        <v-row dense align="center" justify="space-between">
          <v-col cols="8" class="d-flex flex-column align-start">
            <h1
              class="fs-50 fw-900 mb-2"
              :style="`color:${colors.header_text.color};`"
            >
              INVOICE
            </h1>
            <strong
              class="fw-500"
              :style="`color:${colors.header_text.color};`"
            >
              Invoice No.
              {{ invoice && invoice.id ? `INV-${invoice.id}` : 'INV-TBA' }}
            </strong>
          </v-col>
          <v-col class="d-flex flex-column align-center">
            <v-avatar tile height="100" width="200">
              <v-img
                :lazy-src="settings.loader"
                :src="invoice_logo"
                contain
              ></v-img>
            </v-avatar>
          </v-col>
        </v-row>
        <v-row dense class="my-3" align="center" justify="space-between">
          <v-col
            class="d-flex flex-column"
            :cols="4"
            :style="`color:${colors.header_text.color};`"
          >
            <div class="mb-5">
              <strong class="fw-400">INVOICE TO</strong>
              <h1 v-if="billable_to" class="fw-700 fs-18 my-2">
                {{ billable_to.fullname | ucwords }}
              </h1>
            </div>
            <div>
              <strong class="fw-400">INVOICE FROM</strong>
              <h1 v-if="billable_to" class="fw-700 fs-18 my-2">
                {{ billable_from.fullname | ucwords }}
              </h1>
            </div>
          </v-col>
          <v-col class="d-flex align-center" :cols="8">
            <v-sheet
              height="100"
              color="transparent"
              :style="`color:${colors.header_text.color};`"
              class="d-flex align-center justify-space-between fullwidth"
            >
              <div class="d-flex fullwidth flex-column align-start pa-4">
                <div class="d-flex mb-1">
                  <v-icon small :color="colors.header_text.color" left>
                    mdi-email
                  </v-icon>
                  {{ billable_to ? billable_to.email : '-' }}
                </div>
                <div class="d-flex mb-1">
                  <v-icon small :color="colors.header_text.color" left>
                    mdi-phone
                  </v-icon>
                  {{
                    billable_to && billable_to.contact_number
                      ? billable_to.contact_number
                      : '-'
                  }}
                </div>
                <v-divider
                  class="my-1 fullwidth"
                  :style="`background-color:${colors.header_text.color};`"
                />
                <div class="d-flex mb-1">
                  <v-icon small :color="colors.header_text.color" left>
                    mdi-email
                  </v-icon>
                  {{ billable_from ? billable_from.email : '-' }}
                </div>
                <div class="d-flex mb-1">
                  <v-icon small :color="colors.header_text.color" left>
                    mdi-phone
                  </v-icon>
                  {{
                    billable_from && billable_from.contact_number
                      ? billable_from.contact_number
                      : '-'
                  }}
                </div>
              </div>
              <v-divider
                :style="`background-color:${colors.header_text.color}`"
                vertical
              ></v-divider>
              <div
                class="
                  d-flex
                  flex-column
                  fullwidth
                  pa-4
                  align-start
                  justify-center
                "
              >
                <div class="d-flex mb-2 flex-column">
                  <span class="fw-400 fs-14">Invoice Date: </span>
                  <span
                    class="fw-500 fs-15"
                    v-if="invoice && invoice.type === 'one-time'"
                  >
                    {{ invoice.billing_date | format('LL') }}
                  </span>
                  <span v-else>TBA</span>
                </div>
                <div class="d-flex mb-1 flex-column">
                  <span class="fw-400 fs-14"> Payment Due: </span>
                  <span
                    class="fw-500 fs-15"
                    v-if="invoice && invoice.type === 'one-time'"
                  >
                    {{ invoice.due_date | format('LL') }}
                  </span>
                  <span v-else>TBA</span>
                </div>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet
        :color="colors.body_bg.color"
        class="item-body mx-5 rounded-2 mb-0"
      >
        <table class="item-table fullwidth">
          <thead :style="`background-color:${colors.table_header_bg.color};`">
            <tr>
              <th
                :style="`color:${colors.table_header_text.color};`"
                class="py-3"
              >
                Items
              </th>
              <th
                :style="`color:${colors.table_header_text.color};`"
                class="text-center py-3"
                :width="150"
              >
                Unit Price
              </th>
              <th
                :style="`color:${colors.table_header_text.color};`"
                class="text-center py-3"
                :width="150"
              >
                Quantity
              </th>
              <th
                :style="`color:${colors.table_header_text.color};`"
                class="text-center py-3"
                :width="150"
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody v-if="invoice">
            <tr
              v-for="(item, index) in invoice.items"
              :key="index"
              :style="`color:${colors.body_text.color};background-color:${colors.body_bg.color};`"
              class="fw-500"
            >
              <td class="py-3 px-1">
                {{ item.description || 'No description' | ucwords }}
              </td>
              <td class="text-center">{{ item.price_per_unit | money }}</td>
              <td class="text-center">{{ item.quantity }}</td>
              <td class="text-center">{{ item.total | money }}</td>
            </tr>
          </tbody>
        </table>
      </v-sheet>
      <v-row dense no-gutters class="mx-5" align="stretch" justify="center">
        <v-col :cols="5" class="d-flex py-2 pr-1 flex-column">
          <strong
            class="fw-700 fs-20"
            :style="`color:${colors.body_text.color};`"
          >
            Notes
          </strong>
          <p class="caption" :style="`color:${colors.body_text.color};`">
            {{ invoice ? invoice.notes : '-' | truncate(400) }}
          </p>
        </v-col>
        <v-col :cols="7" class="border-1 border-top-3 border-color--grey">
          <div
            :style="`color:${colors.body_text.color};background-color:${colors.body_bg.color};`"
            class="d-flex pa-5 pa-3 flex-column fw-500 fs-15"
          >
            <v-row align="center" dense justify="space-between" class="px-5">
              <v-col cols="6" class="text-right">Subtotal:</v-col>
              <v-col cols="6" class="text-center">
                {{ sub_total | money }}
              </v-col>
            </v-row>
            <v-row align="center" dense justify="space-between" class="px-5">
              <v-col cols="6" class="text-right">Discount:</v-col>
              <v-col cols="6" class="text-center">
                {{ discount_amount | money }}
              </v-col>
            </v-row>
            <v-row align="center" dense justify="space-between" class="px-5">
              <v-col cols="6" class="text-right">Shipping:</v-col>
              <v-col cols="6" class="text-center">
                {{ shipping_amount | money }}
              </v-col>
            </v-row>
            <v-row align="center" dense justify="space-between" class="px-5">
              <v-col cols="6" class="text-right">Tax:</v-col>
              <v-col cols="6" class="text-center">
                {{ tax_amount | money }}
              </v-col>
            </v-row>
          </div>
          <div
            :style="`color:${colors.header_text.color};background-color:${colors.header_bg.color};`"
            class="d-flex pa-5 pa-3 flex-column fw-600 fs-18"
          >
            <v-row align="center" dense justify="space-between" class="px-5">
              <v-col cols="6" class="text-right">TOTAL DUE</v-col>
              <v-col cols="6" class="text-center">
                {{ total_after_addons | money }}
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row dense class="ma-5" align="stretch" justify="space-between">
        <v-col :cols="5" class="d-flex flex-column align-start justify-start">
          <strong
            class="fw-700 fs-20"
            :style="`color:${colors.body_text.color};`"
          >
            Contact Us
          </strong>
          <div class="d-flex align-center flex-column justify-start mt-3">
            <div class="d-flex flex-column">
              <strong
                class="fw-700 fs-13"
                :style="`color:${colors.body_text.color};`"
              >
                PHONE
              </strong>
              <span
                class="fs-12 fw-500"
                :style="`color:${colors.body_text.color};`"
              >
                {{ billable_from ? billable_from.location : '-' | ucwords }}
              </span>
            </div>
            <div class="d-flex flex-column">
              <strong
                class="fw-700 fs-13"
                :style="`color:${colors.body_text.color};`"
              >
                EMAIL
              </strong>
              <span
                class="fs-12 fw-500"
                :style="`color:${colors.body_text.color};`"
              >
                {{ billable_from ? billable_from.location : '-' | ucwords }}
              </span>
            </div>
            <div class="d-flex flex-column">
              <strong
                class="fw-700 fs-13"
                :style="`color:${colors.body_text.color};`"
              >
                ADDRESS
              </strong>
              <span
                class="fs-12 fw-500"
                :style="`color:${colors.body_text.color};`"
              >
                {{ billable_from ? billable_from.location : '-' | ucwords }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col :cols="7" class="d-flex flex-column align-start justify-start">
          <strong
            class="fw-700 fs-20"
            :style="`color:${colors.body_text.color};`"
          >
            Terms & Conditions
          </strong>
          <p class="caption mt-3" :style="`color:${colors.body_text.color};`">
            {{
              invoice && invoice.terms ? invoice.terms : 'None' | truncate(400)
            }}
          </p>
        </v-col>
      </v-row>
      <v-row dense align="center" justify="center" class="mt-5 mb-4 mx-5">
        <div
          :style="`background-color:${colors.header_bg.color};`"
          style="width: 1%; height: 10px"
        ></div>
        <div
          :style="`background-color:${colors.body_bg.color};`"
          style="width: 2%; height: 10px"
        ></div>
        <div
          :style="`background-color:${colors.header_bg.color};`"
          style="width: 3%; height: 10px"
        ></div>
        <div
          :style="`background-color:${colors.body_bg.color};`"
          style="width: 3%; height: 10px"
        ></div>
        <div
          :style="`background-color:${colors.header_bg.color};`"
          style="width: 4%; height: 10px"
        ></div>
        <div
          :style="`background-color:${colors.body_bg.color};`"
          style="width: 4%; height: 10px"
        ></div>
        <div
          :style="`background-color:${colors.header_bg.color};`"
          style="width: 82%; height: 10px"
        ></div>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: 'InvoiceDefault1',
  props: {
    value: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    createMode: { type: Boolean, default: true },
    overflow: { type: Boolean, default: false }
  },
  data: () => ({
    colors: {
      header_bg: {
        color: '#102766ED',
        name: 'Header Background Color',
        show: false
      },
      header_text: {
        color: '#FFFFFFFF',
        name: 'Header Text Color',
        show: false
      },
      body_bg: {
        color: '#FFFFFFFF',
        name: 'Body Background Color',
        show: false
      },
      body_text: {
        color: '#5C5757',
        name: 'Body Text Color',
        show: false
      },
      table_header_bg: {
        color: '#181347ED',
        name: 'Table Header Background Color',
        show: false
      },
      table_header_text: {
        color: '#FFFFFFFF',
        name: 'Table Header Text Color',
        show: false
      }
    },
    html: ''
  }),
  computed: {
    ...mapGetters('invoice', [
      'invoice',
      'invoice_logo',
      'billable_from',
      'billable_to',
      'sub_total',
      'tax_amount',
      'shipping_amount',
      'discount_amount',
      'total_after_addons'
    ]),
    today() {
      return moment().format('ll')
    }
  },
  created() {
    this.$emit('input', this.colors)
  },
  methods: {
    test() {
      //   this.$emit("html", this.$refs.myhtml);
      console.log(this.$refs.myhtml.$el.innerHTML)
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) this.colors = val
      },
      immediate: true,
      deep: true
    },
    colors: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-template-default-1 {
  .item-body {
    margin-top: -50px;

    .item-table {
      border-collapse: collapse;
      tr {
        border: 1px solid #e0e0e0;
      }
      td {
        border: 1px solid #e0e0e0;
      }
    }
  }
}
</style>
