<template>
  <v-sheet ref="myhtml" :class="{ 'overflow-x-auto': overflow }">
    <v-card
      min-height="100vh"
      flat
      max-width="842"
      :min-width="overflow ? 842 : null"
      id="html"
      class="
        invoice-template-default-3
        fill-height
        overflow-hidden
        border-color--grey
      "
      outlined
      :color="colors.primary_bg.color"
    >
      <v-row dense no-gutters align="stretch" justify="center">
        <v-col
          cols="4"
          class="d-flex offset-1 flex-column align-stretch justify-end"
        >
          <div class="d-flex align-center justify-center">
            <div
              :style="`color:${colors.primary_text.color};`"
              class="d-flex fullwidth flex-column align-start justify-center"
            >
              <v-avatar
                class="mt-10 mb-2"
                tile
                height="80"
                max-width="190"
                width="100%"
              >
                <v-img
                  :lazy-src="settings.loader"
                  contain
                  :src="invoice_logo"
                ></v-img>
              </v-avatar>
              <v-row
                class="fullwidth"
                dense
                no-gutters
                align="center"
                justify="center"
              >
                <v-col class="fs-15 ls-unset fw-400" cols="5">
                  Invoice No.
                </v-col>
                <v-col class="fs-15 ls-unset fw-400" cols="7">
                  :
                  {{ invoice && invoice.id ? `INV-${invoice.id}` : 'INV-TBA' }}
                </v-col>
              </v-row>
              <v-row
                class="fullwidth"
                dense
                no-gutters
                align="center"
                justify="center"
              >
                <v-col class="fs-15 ls-unset fw-400" cols="5">
                  Invoice Date
                </v-col>
                <v-col
                  class="fs-15 ls-unset fw-400"
                  cols="7"
                  v-if="invoice && invoice.type === 'one-time'"
                >
                  : {{ invoice.billing_date | format('LL') }}
                </v-col>
                <v-col class="fs-15 ls-unset fw-400" cols="7" v-else>
                  : TBA
                </v-col>
              </v-row>
              <v-row
                class="fullwidth"
                dense
                no-gutters
                align="center"
                justify="center"
              >
                <v-col class="fs-15 ls-unset fw-400" cols="5">
                  Invoice Due
                </v-col>
                <v-col
                  class="fs-15 ls-unset fw-400"
                  cols="7"
                  v-if="invoice && invoice.type === 'one-time'"
                >
                  : {{ invoice.due_date | format('LL') }}
                </v-col>
                <v-col class="fs-15 ls-unset fw-400" cols="7" v-else>
                  : TBA
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col
          cols="7"
          :style="`background-color:${colors.secondary_bg.color};max-height:100px;`"
          class="
            d-flex
            pa-7
            mt-10
            rounded-tl-50 rounded-bl-50
            align-center
            justify-center
          "
        >
          <div class="d-flex mx-auto align-center justify-start">
            <v-avatar
              class="mr-1"
              size="30"
              icon
              :color="colors.secondary_text.color"
            >
              <v-icon small color="primary">mdi-map-marker</v-icon>
            </v-avatar>
            <span :style="`color:${colors.secondary_text.color};`">
              {{
                invoice_company && invoice_company.address
                  ? invoice_company.address
                  : '-'
              }}
            </span>
          </div>
          <div class="d-flex mx-auto align-center justify-start">
            <v-avatar
              class="mr-1"
              size="30"
              icon
              :color="colors.secondary_text.color"
            >
              <v-icon small color="primary">mdi-phone</v-icon>
            </v-avatar>
            <span :style="`color:${colors.secondary_text.color};`">
              {{
                invoice_company && invoice_company.contact
                  ? invoice_company.contact.e164
                  : '-'
              }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row align="start" dense justify="center">
        <v-col cols="10">
          <v-row
            :style="`color:${colors.primary_text.color};`"
            dense
            no-gutters
            align="stretch"
            justify="center"
            class="mt-10"
          >
            <v-col cols="6" class="d-flex flex-column">
              <strong>Invoice From:</strong>
              <h2>
                {{ billable_from ? billable_from.fullname : '-' | ucwords }}
              </h2>
              <span>
                {{ billable_from ? billable_from.email : '-' }}
              </span>
              <span v-if="billable_from && billable_from.contact_number">
                {{ billable_from.contact_number }}
              </span>
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <strong>Invoice To:</strong>
              <h2>
                {{ billable_to ? billable_to.fullname : '-' | ucwords }}
              </h2>
              <span>
                {{ billable_to ? billable_to.email : '-' }}
              </span>
              <span v-if="billable_to && billable_to.contact_number">
                {{ billable_to.contact_number }}
              </span>
            </v-col>
          </v-row>
          <v-row class="my-5" align="stretch" dense justify="center">
            <v-col cols="2">
              <div
                :style="`background-color:${colors.tertiary_bg.color};`"
                class="wrapper d-flex align-center justify-center"
              >
                <h3 :style="`color:${colors.tertiary_text.color};`">INVOICE</h3>
              </div>
              <div style="clear: both"></div>
            </v-col>
            <v-col cols="10" class="d-block py-3 px-7">
              <v-sheet
                min-height="100"
                class="item-body fullwidth rounded-2 mt-5 mb-5"
              >
                <table
                  class="
                    item-table
                    fullwidth
                    border-bottom-2
                    border-color--grey
                  "
                >
                  <thead
                    class="border-top-2 border-bottom-2 border-color--grey"
                  >
                    <tr>
                      <th
                        :style="`color:${colors.primary_text.color};text-align:left;`"
                        class="py-3 pl-1"
                      >
                        Items
                      </th>
                      <th
                        :style="`color:${colors.primary_text.color};`"
                        class="text-center py-3"
                        :width="100"
                      >
                        Unit Price
                      </th>
                      <th
                        :style="`color:${colors.primary_text.color};`"
                        class="text-center py-3"
                        :width="100"
                      >
                        Quantity
                      </th>
                      <th
                        :style="`color:${colors.primary_text.color};`"
                        class="text-center py-3"
                        :width="100"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="invoice">
                    <tr
                      v-for="(item, index) in invoice.items"
                      :key="index"
                      class="fw-500"
                      :style="`color:${colors.primary_text.color};`"
                    >
                      <td class="py-3 px-1">
                        {{ item.description || 'No description' | ucwords }}
                      </td>
                      <td class="text-center">
                        {{ item.price_per_unit | money }}
                      </td>
                      <td class="text-center">{{ item.quantity }}</td>
                      <td class="text-center">{{ item.total | money }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-sheet>
              <v-sheet
                color="transparent"
                class="
                  fullwidth
                  d-flex
                  align-end
                  justify-end
                  flex-column
                  rounded-2
                  mt-5
                  mb-5
                "
              >
                <v-row
                  :style="`color:${colors.primary_text.color};`"
                  class="halfwidth"
                  dense
                  align="center"
                  justify="end"
                >
                  <v-col
                    cols="6"
                    class="d-flex fs-16 fw-500 align-center justify-end"
                  >
                    Subtotal
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex fs-16 fw-500 align-center justify-end"
                  >
                    {{ sub_total | money }}
                  </v-col>
                </v-row>
                <v-row
                  :style="`color:${colors.primary_text.color};`"
                  class="halfwidth"
                  dense
                  align="center"
                  justify="end"
                >
                  <v-col
                    cols="6"
                    class="d-flex fs-16 fw-500 align-center justify-end"
                  >
                    Discount
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex fs-16 fw-500 align-center justify-end"
                  >
                    {{ discount_amount | money }}
                  </v-col>
                </v-row>
                <v-row
                  :style="`color:${colors.primary_text.color};`"
                  class="halfwidth"
                  dense
                  align="center"
                  justify="end"
                >
                  <v-col
                    cols="6"
                    class="d-flex fs-16 fw-500 align-center justify-end"
                  >
                    Shipping
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex fs-16 fw-500 align-center justify-end"
                  >
                    {{ shipping_amount | money }}
                  </v-col>
                </v-row>
                <v-row
                  :style="`color:${colors.primary_text.color};`"
                  class="halfwidth"
                  dense
                  align="center"
                  justify="end"
                >
                  <v-col
                    cols="6"
                    class="d-flex fs-16 fw-500 align-center justify-end"
                  >
                    Tax
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex fs-16 fw-500 align-center justify-end"
                  >
                    {{ tax_amount | money }}
                  </v-col>
                </v-row>
                <v-row
                  :style="`color:${colors.primary_text.color};`"
                  class="halfwidth"
                  dense
                  align="center"
                  justify="end"
                >
                  <v-col
                    cols="6"
                    class="d-flex fw-900 fs-25 align-center justify-start"
                  >
                    Total
                  </v-col>
                  <v-col cols="6" class="d-flex fs-25 align-center justify-end">
                    {{ total_after_addons | money }}
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="12">
              <v-row
                dense
                align="start"
                justify="start"
                :style="`color:${colors.primary_text.color};`"
              >
                <v-col cols="6" class="d-flex align-start justify-start">
                  <v-icon :color="colors.primary_text.color" left>
                    mdi-ray-end
                  </v-icon>
                  <div>
                    <strong>Notes</strong>
                    <p class="caption">
                      {{
                        invoice && invoice.notes
                          ? invoice.notes
                          : '-' | truncate(400)
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="6" class="d-flex align-start justify-start">
                  <v-icon :color="colors.primary_text.color" left>
                    mdi-ray-end
                  </v-icon>
                  <div>
                    <strong>Terms & Conditions</strong>
                    <p class="caption">
                      {{
                        invoice && invoice.terms
                          ? invoice.terms
                          : '-' | truncate(400)
                      }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <div
          style="bottom: 20%; left: 0; z-index: 3"
          :style="`background-color:${colors.secondary_bg.color};`"
          class="
            border-top-2
            mt-3
            bubble
            border-right-2 border-bottom-2
            rounded-tr-50 rounded-br-50
          "
        ></div>
        <div
          style="bottom: 22%; left: 0; z-index: 2"
          :style="`border-color:${colors.tertiary_bg.color};`"
          class="
            border-top-2
            mt-3
            bubble
            border-right-2 border-bottom-2
            rounded-tr-50 rounded-br-50
          "
        ></div>
        <div
          style="top: 2%; left: 0; z-index: 2"
          :style="`border-color:${colors.tertiary_bg.color};`"
          class="
            border-top-2
            mt-3
            bubble
            border-right-2 border-bottom-2
            rounded-tr-50 rounded-br-50
          "
        ></div>
        <div
          style="top: 35%; right: 0; z-index: 2"
          :style="`border-color:${colors.tertiary_bg.color};`"
          class="
            border-top-2
            mt-3
            bubble
            border-left-2 border-bottom-2
            rounded-tl-50 rounded-bl-50
          "
        ></div>
      </v-row>
      <v-row
        no-gutters
        align="center"
        :style="`background-color:${colors.secondary_bg.color};`"
        class="mt-5 pr-15"
        dense
        justify="end"
      >
        <div
          class="stripe"
          :style="`background-color:${colors.tertiary_bg.color};`"
        ></div>
        <div
          class="stripe"
          :style="`background-color:${colors.tertiary_bg.color};`"
        ></div>
        <div
          class="stripe"
          :style="`background-color:${colors.tertiary_bg.color};`"
        ></div>
        <div
          class="stripe"
          :style="`background-color:${colors.tertiary_bg.color};`"
        ></div>
        <div
          class="stripe"
          :style="`background-color:${colors.tertiary_bg.color};`"
        ></div>
        <div
          class="stripe"
          :style="`background-color:${colors.tertiary_bg.color};`"
        ></div>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: 'InvoiceDefault3',
  props: {
    value: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    createMode: { type: Boolean, default: true },
    overflow: { type: Boolean, default: false }
  },
  data: () => ({
    colors: {
      primary_bg: {
        color: '#CCCBBC',
        name: 'Primary Background Color',
        show: false
      },
      primary_text: {
        color: '#5C4335',
        name: 'Primary Text Color',
        show: false
      },
      secondary_bg: {
        color: '#3c599e',
        name: 'Secondary Background Color',
        show: false
      },
      secondary_text: {
        color: '#FFFFFFFF',
        name: 'Secondary Text Color',
        show: false
      },
      tertiary_bg: {
        color: '#1ba6df',
        name: 'Tertiary Background Color',
        show: false
      },
      tertiary_text: {
        color: '#FFFFFFFF',
        name: 'Tertiary Text Color',
        show: false
      }
    },
    html: ''
  }),
  computed: {
    ...mapGetters('invoice', [
      'invoice',
      'invoice_company',
      'invoice_logo',
      'billable_from',
      'billable_to',
      'sub_total',
      'tax_amount',
      'shipping_amount',
      'discount_amount',
      'total_after_addons'
    ]),
    today() {
      return moment().format('ll')
    }
  },
  created() {
    this.$emit('input', this.colors)
  },
  methods: {
    test() {
      //   this.$emit("html", this.$refs.myhtml);
      console.log(this.$refs.myhtml.$el.innerHTML)
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) this.colors = val
      },
      immediate: true,
      deep: true
    },
    colors: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-template-default-3 {
  .wrapper {
    min-height: 300px;
    display: block;
    padding: 15px;

    h3 {
      font-size: 3rem;
      font-weight: 900;
      color: white;
      text-transform: uppercase;
      letter-spacing: 5px;
      position: relative;
      left: 0;
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      transform: rotate(270deg);
      -webkit-transform-origin: 110px 10px;
      -moz-transform-origin: 110px 10px;
      -ms-transform-origin: 110px 10px;
      -o-transform-origin: 110px 10px;
      transform-origin: 110px 10px;
    }
  }
  .item-body {
    background: transparent;
    .item-table {
      border-collapse: collapse;
      tr {
        border: none;
      }
      td {
        border: none;
      }
    }
  }
  .bubble {
    width: 50px;
    height: 100px;
    position: absolute;
  }
  .stripe {
    width: 10px;
    margin-left: 5px;
    height: 20px;
  }
}
</style>
